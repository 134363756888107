import styled from 'styled-components';

export const FlexContainer = styled.div`
  max-width: 1140px;
  margin-top: 1rem;
  margin-left: 1rem;
  display: flex;

  @media (max-width: 630px) {
    display: flex;
  }

  .itens {
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media (max-width: 500px) and (min-width: 488px) {
      width: 65%;
      flex-wrap: wrap;
      margin-right: 1.5rem;
    }
    @media (max-width: 487px) {
      width: 75%;
      flex-wrap: wrap;
      margin-left: 1rem;
      margin-right: 0rem;
    }
    @media (max-width: 422px) {
      width: 70%;
      flex-wrap: wrap;
      margin-left: 1rem;
      margin-right: 0rem;
    }

    @media (max-width: 380px) {
      width: 80%;
      flex-wrap: wrap;
      margin-left: 1rem;
      margin-right: 0rem;
    }
    @media (max-width: 320px) {
      width: 100%;
      display: grid;
      justify-content: center;
      margin-left: 0rem;
      margin-right: 4rem;
    }
  }

  .item {
    margin-bottom: 1rem;
    margin-right: 5rem;
    @media (max-width: 655px) {
      margin-right: 1rem;
    }
    @media (max-width: 560px) {
      margin-right: 0rem;
    }
    @media (max-width: 500px) {
      padding-bottom: 2rem;
    }
  }

  .app {
    margin-top: 2rem;
    margin-left: 1rem;
    @media (max-width: 1230px) {
      display: flex;
    }
    @media (max-width: 365px) {
      width: 80%;
      margin-left: 0rem;
    }
    @media (max-width: 335px) {
      width: 100%;
      display: grid;
      justify-content: center;
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }

  .isoRecl {
    margin-top: 2rem;
    margin-left: 1rem;
    @media (max-width: 1230px) {
      display: flex;
      margin-top: 0rem;
      margin-left: 0rem;
    }
    @media (max-width: 800px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 365px) {
      width: 80%;
      margin-left: 0rem;
    }
    @media (max-width: 335px) {
      width: 100%;
      display: grid;
      justify-content: center;
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }

  .sac {
    img {
      width: 15px;
      margin-right: 3px;
      margin-bottom: -2.5px;
    }
    @media (max-width: 500px) {
      width: 111px;
    }
  }

  span {
    color: #505d68;
    margin-bottom: 2rem;
  }

  p {
    color: #a1aeb7;
    margin-top: 1rem;
    margin-right: 1rem;
  }

  @media (max-width: 1230px) {
    flex-wrap: wrap;
    width: 90%;
  }

  @media (max-width: 910px) {
    justify-content: center;
  }

  @media (max-width: 396px) {
    display: flex;
    align-items: left;
    justify-content: left;
    width: 90%;
  }

  @media (max-width: 420px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const Line = styled.div`
  border-top: 1px solid #505d68;
  margin-top: 6rem;
  margin-bottom: 1.5rem;
`;

export const LastContainer = styled.div`
  display: flex;
  margin-bottom: 4rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  p {
    color: #505d68;

    @media (max-width: 625px) {
      flex-wrap: wrap;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 625px) {
    flex-wrap: wrap;
    width: 90%;
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    width: 90%;
  }
`;
