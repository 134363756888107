import React from 'react';

import { LastContainer, FlexContainer, Line } from './styles';
import { Content } from '../../../styles/components';
import AppStore from '../../../assets/Home/appstore.svg';
import PlayStore from '../../../assets/Home/playstore.svg';
import Iso from '../../../assets/Home/Certificado_ISO_9001_2.png';
import ReclameAqui from '../../../assets/Home/reclame_aqui.png';
import framewhatsapp from '../../../assets/Shared/framewhatsapp.svg';

const Footer: React.FC = () => {
  return (
    <>
      <Content>
        <FlexContainer>
          <div className="itens">
            <div className="item">
              <span>PRODUTOS</span>
              <p>
                <a href="/cartoes-recarga">Cartões BHBUS</a>
              </p>
              <p>
                <a href="/para-empresas">Para Empresas</a>
              </p>
              <p>
                <a href="/lojas-locais-recarga">Lojas de Recarga</a>
              </p>
              <p>
                <a href="/rotas-e-linhas">Rotas e Linhas</a>
              </p>
            </div>

            <div className="item">
              <span>O TRANSFÁCIL</span>
              <p>
                <a href="/quem-somos">Quem Somos</a>
              </p>
              <p>
                <a href="/vagas-disponiveis">Trabalhe Conosco</a>
              </p>
              <p>
                <a href="/noticias">Notícias</a>
              </p>
              <p>
                <a href="/parceiros" target="_blank">
                  Parceiros
                </a>
              </p>
              <p>
                <a href="/compliance">Canal de Denúncias</a>
              </p>
            </div>

            <div className="item">
              <span>REDES SOCIAIS</span>
              <p>
                <a
                  href="https://www.facebook.com/oficialtransfacil"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </a>
              </p>
              <p>
                <a
                  href="https://www.youtube.com/channel/UCHeYUO3beSjsAt0LIjJF24Q/featured"
                  target="_blank"
                  rel="noreferrer"
                >
                  Youtube
                </a>
              </p>
              <p>
                <a
                  href="https://www.instagram.com/oficialtransfacil/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/company/transfacil/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Linkedin
                </a>
              </p>
            </div>

            <div className="sac">
              <span>SAC</span>
              <p>
                <a href="mailto:sac@transfacil.com.br">sac@transfacil.com.br</a>
              </p>
              <p>
                <a
                  href="https://api.whatsapp.com/send?phone=553132487300&text=Olá%20Transfácil"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={framewhatsapp} alt="whatsapp" />
                  (31) 3248-7300
                </a>
              </p>
              <p>
                <a
                  href="https://goo.gl/maps/VWvcM8qpa8S3vQj67"
                  target="_blank"
                  rel="noreferrer"
                >
                  Rua Aquiles Lobo, 504 - <br /> Floresta BH
                </a>
              </p>
              {/* <p>
                <a
                  href="mailto:sac@transfacil.com.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ouvidoria
                </a>
              </p> */}
            </div>
          </div>

          <div className="app">
            <p>
              <a
                href="https://apps.apple.com/br/app/bhbus/id1394245562"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStore}
                  alt="Imagem de um cartão sendo passado no leitor do ônibus"
                  className="appstore"
                />
              </a>
            </p>
            <p>
              <a
                href="https://play.google.com/store/apps/details?id=br.com.transfacil"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={PlayStore}
                  alt="Imagem de um cartão sendo passado no leitor do ônibus"
                  className="playstore"
                />
              </a>
            </p>
          </div>
          <div className="isoRecl">
            <p>
              <img
                src={Iso}
                alt="Imagem de um cartão sendo passado no leitor do ônibus"
                className="appstore"
                style={{
                  height: 100,
                  width: 'auto',
                  margin: '0px -12px -12px -12px',
                }}
              />
            </p>
            {/* <p>
              <img
                src={ReclameAqui}
                alt="Imagem de um cartão sendo passado no leitor do ônibus"
                className="playstore"
              />
            </p> */}
          </div>
        </FlexContainer>
      </Content>
      <Line />
      <Content>
        <LastContainer>
          <p>CNPJ: 04.398.505/0001-07</p>
          <p>
            <a href="/termos-de-uso" target="_blank">
              Termos de Uso e Condições
            </a>
          </p>

          <p>
            <a href="/politica-privacidade" target="_blank">
              Políticas de Segurança e Privacidade
            </a>
          </p>

          <p>©2021 - Transfácil | All right reserved</p>
        </LastContainer>
      </Content>
    </>
  );
};

export default Footer;
